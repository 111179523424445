import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';

import { Button, Grid, InputLabel, MenuItem, Select, withStyles, Dialog, Typography } from '@material-ui/core';
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import {ListItemStyle} from './ListItemStyle';
import { ListItemGrid } from './ListItemGrid';

import { getInstance, syncTranType, deleteTranType } from '../utils/axiosLoader';
import Constants from '../utils/constants';
import LoadingOverlay from '../common/loadingOverlay';
import NewListItem from './NewListItem';
import {concat as _concat } from 'lodash';
import './ListItem.css';
import mapDispatchToProps from '../actions/dispatchProps';
import mapStateToProps from "../actions/stateToProps";
import ListItemHandler from '../api/ListItemHandler';

let ACCOUNT_TYPE_CODE = 'account_type';

class ListItem extends Component {
    constructor(props) {
		super(props);
		this._isMounted = false; // prevents -> Warning: Can't perform a React state update on an unmounted component
		this._handler = new ListItemHandler(this);
		this.handleMajorFieldChange = this.handleMajorFieldChange.bind(this)
	}

	state = {
		enabledAdd: false,
		enabledAddMajor: true, /* always available */
        enabledDelete: false,
        enabledEnable: false,
		enabledDeleteMajor: false,
		enabledEnableMajor: false,
		actionInProgress      : {
			openLinearLoading   : false,
			disableActionButton : false
		},
		listMajor: [{name: 'Select One', code: 'default', list: 'list_major'}],
		listItems: [],
		open: {
			createModal   : false,
            addMajorModal: false,
            disableConfirmation: false,
            enableConfirmation: false,
		},
		openOverlay              : false,
		rowsSelected: [],
		selected: {name: 'Select One', code: 'default', list: 'list_major'},
		tranTypes: [],
		showTranTypeGrid: false,
		tranTypeRowsSelected: [],
		postTranTypes: {
			none: false,
			cr: false,
			dr: false,
			both: false
		}
	};

	componentDidMount = () => {
		this._isMounted = true;

		if (this._isMounted) {
			this.props.changePageTitle('Manage List Items')
			this.handleGetLists();
			this.handleGetTranTypes();
		}
	};

	componentWillUnmount = () => {
		this._isMounted = false;
	};

	handleMajorFieldChange(e) {
		let selectedItems = this.state.listMajor.filter((item) => {
			return item.code === e.target.value;
		})
		this.setState({ 
			enabledAdd: (e.target.value !== 'default'), 
            enabledDelete: false, 
            enabledEnable: false,
			selected: selectedItems[0],
			enabledDeleteMajor: false,
			enabledEnableMajor: false,
			showTranTypeGrid: false
		});
		if (this.gridApi) this.gridApi.sizeColumnsToFit();
		this.handleRefreshListItemGrid(e.target.value);
	}

	handleGetLists = async () => {
		let url = Constants.URLs.LIST_ITEMS_LIST.LIST_MAJOR;
		let defaultItem = {name: 'Select One', code: 'default', list: 'list_major'};
		this.setState({ openOverlay: true })
		await getInstance()
			.get(url)
			.then((response) => {
				let ac = response.data.filter(ac=>ac.status === 'ac');
				let others = response.data.filter(ac=>ac.status !== 'ac');
				this.setState({
					openOverlay: false,
					listMajor: _concat(defaultItem, ac, others),
					selected: defaultItem
				});
				this.handleRefreshListItemGrid(defaultItem.code)
			})
			.catch((error) => {
				console.log(error);
				this.props.enqueueSnackbar('Error: Could not be able to retrieve major list', {
					variant : 'error'
				});
				this.setState({ openOverlay: false, selected: defaultItem })
				this.handleRefreshListItemGrid(defaultItem.code)
			});
	}

	
	handleGetTranTypes = async (type='DEF') => {
		let url = Constants.URLs.TRAN_TYPES + '/ac';
		this.setState({ openOverlay: true })
		await getInstance()
			.post(url, {acType: type})
			.then((response) => {
				this.setState({
					openOverlay: false,
					tranTypes: response.data
				});
			})
			.catch((error) => {
				console.log(error);
				this.props.enqueueSnackbar('Error: Could not be able to retrieve transaction types', {
					variant : 'error'
				});
				this.setState({ openOverlay: false })
			});
	}

	handleRefreshListItemGrid = async (listCode = this.state.selected.code) => {
		if (listCode !== 'default') {
			let retrieveFrom = `${Constants.URLs.LIST_ITEMS_LIST.BASE}/${listCode}`;
			this.setState({ openOverlay: true });

			await getInstance()
				.get(retrieveFrom)
				.then((response) => {
					let ac_cnt = response.data.filter(item=>item.status==='ac');
					this.setState({ 
						openOverlay: false,
						enabledAdd: true, 
						enabledDeleteMajor: (ac_cnt === 0 && this.state.selected.status !== 'in'), 
						enabledEnableMajor: this.state.selected.status === 'in',
						listItems: response.data })
				})
				.catch((error) => {
					console.log(error);
					this.props.enqueueSnackbar('Error: Could not be able to retrieve list items', {
						variant : 'error'
					});
					this.setState({ openOverlay: false,enabledAdd: false, listItems: [] });
				});
		} else {
			this.setState({ openOverlay: false, enabledAdd: false, listItems: [] });
		}
	};
	
	handleOpenModal = (modal) => {
		let showModal = this.state.open;
		switch (modal) {
			case 'add':
                showModal.createModal = true;
				showModal.addMajorModal = false;
				break;
			case 'add_major':
                showModal.createModal = true;
				showModal.addMajorModal = true;
                break;
            case 'disable-confirmation':
                showModal.disableConfirmation = true;
                break;
            case 'enable-confirmation':
                showModal.enableConfirmation = true;
                break;
			default:
				break;
		}

		this.setState({
			open                  : showModal,
			actionInProgress : {disableActionButton: true}
		});
	};

	handleCloseModal = (modal) => {
		let showModal = this.state.open;
		showModal.createModal = false;
        showModal.addMajorModal = false;
        showModal.disableConfirmation = false;
        showModal.enableConfirmation = false;

		this.setState({
			open             : showModal,
		});
	};

	handleNewListItemSubmit = (params) => {
		let {list, code, isMajor, status, msg} = params;
		this.handleCloseModal('add');
		if (status === 'success') {
			if (isMajor) {
				this.handleGetLists(); //this will also resets the value & grid
			} else {
				this.handleRefreshListItemGrid();
			}
			if (list === 'currencies') {
				this.reloadCurrencies();
			}
			if (list === ACCOUNT_TYPE_CODE) {
				this.handleGetAcctTypes();
				this.handleSyncTranType('ac', code);
			}
			if (list === 'tran_type') {
				this.handleSyncTranType('tran', code);
			}
			this.props.enqueueSnackbar(msg, {
				variant : 'success'
			});
		} else {
			this.props.enqueueSnackbar(msg, {
				variant : 'error'
			});
		}
	}

	reloadCurrencies = async () => {
		// await getNewCurrencies()
		this._handler.getCurrencies(true)
			.then((data) => {
					this.setState({ currencies: data });
			})
			.catch((err) => {
					console.log('error while fetching currencies:' + err.message);
			})
	}	

	handleGetAcctTypes = async () => {
		this._handler.getAcctTypes(true)
			.then((data) => {
					console.log(data);
					this.setState({ accountTypes: data });
			})
			.catch((err) => {
					console.log('error while fetching account types:' + err.message);
			})
	}

	handleSyncTranType = async(by, code) => {
		await syncTranType(by, code)
			.then((data) => {
					console.log(data);
			})
			.catch((err) => {
					console.log('error while syncing tran types:' + err.message);
			})
	}
	
	handleDeleteTranType = async(by) => {
		let codes = this.state.rowsSelected.reduce((arr, item) => {
			arr.push(item.code);
			return arr;
		}, []);
		console.log(by + ' codes to delete', codes);
		//TODO: create an endpoint to delete array of elements
		codes.forEach(async (code)=>{
			await deleteTranType(by, code)
					.then((data) => {
							console.log(data);
					})
					.catch((err) => {
							console.log('error while deleting account types:' + err.message);
					})
		})
	}

	handleOnGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.gridApi.sizeColumnsToFit();
	};

	onSelectionChanged() {
		let selectedRows = this.gridApi.getSelectedRows();
		if (this.state.selected.code === ACCOUNT_TYPE_CODE && selectedRows[0].code) {
			this.handleGetTranTypes(selectedRows[0].code)
		}
		this.setState({ 
			rowsSelected: selectedRows, 
            enabledDelete: ((selectedRows.length > 1) || (selectedRows.length === 1 && selectedRows[0].status === 'ac')),
            enabledEnable: ((selectedRows.length > 1) || (selectedRows.length === 1 && selectedRows[0].status === 'in')) && this.state.selected.status === 'ac',
			showTranTypeGrid: this.state.selected.code === ACCOUNT_TYPE_CODE && selectedRows.length === 1,
			postTranTypes: {none: false, cr: false, dr: false, both: false}
		})
	}
	
	handleOnTranTypeGridReady = params => {
		this.gridApi2 = params.api;
		this.gridColumnApi2 = params.columnApi;
		this.gridApi.sizeColumnsToFit();
		this.gridApi2.sizeColumnsToFit();
	};

	onTranTypeSelectionChanged() {
		let _postTranTypes = this.state.postTranTypes;
		_postTranTypes.none = false;
		_postTranTypes.cr = false;
		_postTranTypes.dr = false;
		_postTranTypes.both = false;
		let selectedRows = this.gridApi2.getSelectedRows();
		if (selectedRows.length === 1) {
			_postTranTypes.none = true;
			_postTranTypes.cr = true;
			_postTranTypes.dr = true;
			_postTranTypes.both = true;
			let row = selectedRows[0];
			switch (row.tag) {
				case 'C':
					_postTranTypes.cr = false;
					break;
				case 'D':
					_postTranTypes.dr = false;
					break;
				case 'B':
					_postTranTypes.both = false;
					break;
				default:
					_postTranTypes.none = false;
					break;
			}
			this.setState({ 
				tranTypeRowsSelected: selectedRows, 
				postTranTypes: _postTranTypes,
			})
		} else {
			// when multiple rows selected; enabled all
			_postTranTypes.none = true;
			_postTranTypes.cr = true;
			_postTranTypes.dr = true;
			_postTranTypes.both = true;
			this.setState({ 
				tranTypeRowsSelected: selectedRows, 
				postTranTypes: _postTranTypes,
			})
		}
	}

	
	handleDeleteSelection = async (status="in") => {
		let rowsToDelete = this.state.rowsSelected;
		let selected = rowsToDelete.reduce((arr, item) => {
			arr.push({_id: item._id, status});
			return arr;
        }, []);
        let _open = this.state.open;
        _open.disableConfirmation = false;
        _open.enableConfirmation = false;
        this.setState({ openOverlay: false, open: _open });
		let deleteItemsURL = Constants.URLs.LIST_ITEMS + '/deleteitems';
		await getInstance().post(deleteItemsURL, selected)
			.then((response) => {
				let cnt = response.data.reduce((m, item) => {
					m += item.deleted;
					return m;
				}, 0);
				this.props.enqueueSnackbar(cnt + ' of ' + response.data.length + ' list item(s) modified successfully', {
					variant : 'success'
				});
				//if delete is currencies or account types, then refresh local entries
				if (this.state.selected.code === 'currencies') {
					console.log('refreshing currencies')
					this.reloadCurrencies();
				}
				if (this.state.selected.code === ACCOUNT_TYPE_CODE) {
					this.handleGetAcctTypes();
					this.handleDeleteTranType('ac');
				}
				if (this.state.selected.code === 'tran_type') {
					this.handleDeleteTranType('tran');
				}
			})
			.catch((error) => {
				console.log(error);
				this.props.enqueueSnackbar('Error: Could not delete the list item(s)', {
					variant : 'error'
				});
            })
            .finally(()=>{
                this.setState({ openOverlay: false, enabledDelete: false, enabledEnable: false, rowsSelected: [] })
                this.handleRefreshListItemGrid();
            })
		//this.setState({ openOverlay: false, enabledDelete: false, rowsSelected: [] });
		//this.handleRefreshListItemGrid();
	}

	handleDeleteMajor = async () => {
		let _selected = this.state.selected;
		if (_selected.code !== 'default') {
			this.setState({ enabledDeleteMajor: false, openOverlay: true })
			let deleteItemsURL = Constants.URLs.LIST_ITEMS + '/' + _selected._id;
			await getInstance()
				.patch(deleteItemsURL, {status: 'in'})
				.then((response) => {
					this.props.enqueueSnackbar('list deleted successfully', {
						variant : 'success'
					});
				})
				.catch((error) => {
					console.log(error);
					this.props.enqueueSnackbar('Error: Could not delete the list', {
						variant : 'error'
					});
				});
			this.setState({ openOverlay: false, rowsSelected: [] });
			this.handleGetLists()
		}	
	}

	handleEnableMajor = async () => {
		let _selected = this.state.selected;
		if (_selected.code !== 'default') {
			this.setState({ enabledEnableMajor: false, openOverlay: true })
			let deleteItemsURL = Constants.URLs.LIST_ITEMS + '/' + _selected._id;
			await getInstance()
				.patch(deleteItemsURL, {status: 'ac'})
				.then((response) => {
					this.props.enqueueSnackbar('list Enabled successfully', {
						variant : 'success'
					});
				})
				.catch((error) => {
					console.log(error);
					this.props.enqueueSnackbar('Error: Could not enable the list', {
						variant : 'error'
					});
				});
			this.setState({ openOverlay: false, rowsSelected: [] });
			this.handleGetLists()
		}	
	}
	



	/**
	 * Render UI Components
	 */

	renderActions() {
		return (
			<Grid container style={{marginLefmarginTop: "6px", marginBottom: "4px"}}>
				<Grid item md={12} sm={12} xs={12}>
					<Button disabled={!this.state.enabledAdd}
						onClick={() => this.handleOpenModal('add')}
						variant="contained"
						size="small"
						color="primary">Add Item</Button>
					&nbsp;
					<Button disabled={!this.state.enabledAddMajor}
						onClick={() => this.handleOpenModal('add_major')}
						variant="contained"
						size="small"
						color="primary">Add List</Button>
					&nbsp;
					<Button disabled={!this.state.enabledDelete}
						onClick={() => this.handleOpenModal("disable-confirmation")}
						variant="contained"
						size="small"
						color="secondary">Disable</Button>
					&nbsp;
					<Button disabled={!this.state.enabledEnable}
						onClick={() => this.handleOpenModal("enable-confirmation")}
						variant="contained"
						size="small"
						color="primary">Enable</Button>
					&nbsp;
					<Button disabled={!this.state.enabledDeleteMajor}
						onClick={() => this.handleDeleteMajor()}
						variant="contained"
						size="small"
						title={this.state.listItems.length > 0 ? `List should be empty to be deleted` : `Click to delete this empty list`}
						color="secondary">Disable List</Button>
					&nbsp;
					<Button disabled={!this.state.enabledEnableMajor}
						onClick={() => this.handleEnableMajor()}
						variant="contained"
						size="small"
						title={this.state.selected.status === 'in' ? `Click to enable this inactive list` : `List should be inactive to be enabled`}
						color="secondary">Enable List</Button>
				</Grid>
			</Grid>
		)
	};

	handleTranTypeUpdate(tag='N') {
		let tranTypes = this.state.tranTypeRowsSelected;
		tranTypes.forEach(async (row)=>{
			await getInstance()
					.get(Constants.URLs.TRAN_TYPES + '/patch/' + row._id + '/' + tag)
					.then((data) => {
							console.log(data);
							this.onSelectionChanged();
					})
					.catch((err) => {
                        console.log('error while updating transaction types:' + err.message);
                        let msg = err.message;
						if (err.response && err.response.data && err.response.data.message) {
							msg = err.response.data.message;
						}
                        this.props.enqueueSnackbar(msg, {
                            variant : 'error'
                        });
					})
		})

	}

	renderTranTypeActions() {
		return (
			<Grid container style={{marginLeft: "2%", marginTop: "2px"}}>
				<Grid item md={12} sm={12} xs={12}>
					<Button disabled={!this.state.postTranTypes.none}
						onClick={() => this.handleTranTypeUpdate('N')}
						variant="contained"
						size="small"
						color="default">None</Button>
					&nbsp;
					<Button disabled={!this.state.postTranTypes.cr}
						onClick={() => this.handleTranTypeUpdate('C')}
						variant="contained"
						size="small"
						color="primary">Credit</Button>
					&nbsp;
					<Button disabled={!this.state.postTranTypes.dr}
						onClick={() => this.handleTranTypeUpdate('D')}
						variant="contained"
						size="small"
						color="secondary">Debit</Button>
					&nbsp;
					<Button disabled={!this.state.postTranTypes.both}
						onClick={() => this.handleTranTypeUpdate('B')}
						variant="contained"
						size="small"
						color="inherit">Both</Button>
				</Grid>
			</Grid>
		)
	};

	renderListSelection() {
		const { classes } = this.props;
		return (
			<Grid container style={{marginLeft: "1%"}}>
				<Grid item md={6} sm={6} xs={6}>
					<br />
					<InputLabel>Select Picklist:</InputLabel>
					<Select
						name="select-list-major"
						data-cy="ddListItemSelectMajor"
						value={this.state.selected.code}
						onChange={this.handleMajorFieldChange}
						className={classes.tranReleaseSelectWidth}>
						{this.state.listMajor.map((list, index) => {
							return (
								<MenuItem
									key={`list-${list.code}`}
									value={list.code}>
									{`${list.status === 'in' ? '(Inactive) ' : ''}${list.name}`}
								</MenuItem>
							);
					})}
					</Select>
				</Grid>
			</Grid>
		)
	}

	renderGridTitle() {
		return (
			<Grid container style={{marginLeft: "2%", marginTop: "2px"}}>
				<Grid item>
					<span style={{"fontSize": "14px"}}>Viewing {this.state.listItems.length} items From list:&nbsp; 
					<strong>{this.state.selected.code}</strong></span>
				</Grid>
			</Grid>
		)
	}

	renderGrid() {
		const { classes } = this.props;
		const agStyles = `ag-theme-balham ${classes.agThemeModified}`;
		return (
			<Grid container style={{"height": "60vh"/* , width: "96%", marginLeft: "2%", marginTop: "10px" */}} >
				<Grid item  md={12} sm={12} xs={12}>
					<div className={agStyles}>
						<AgGridReact
							gridOptions={ListItemGrid.gridOptions}
							onGridReady={this.handleOnGridReady}
							columnDefs={ListItemGrid.columns}
							className="my-custom-header"
							rowData={this.state.listItems}
							rowSelection="multiple"
							getRowClass={this.handleMainRowHighlights}
							onSelectionChanged={this.onSelectionChanged.bind(this)}
							animateRows
						/>
					</div>
				</Grid>
			</Grid>
		)
	}

	handleRowHighlights = params => {
		if (params.data.tag === 'B') return 'tran-both-row';
		if (params.data.tag === 'C') return 'tran-credit-row';
		if (params.data.tag === 'D') return 'tran-debit-row';
    }
    
    handleMainRowHighlights = params => {
        if (params.data.status === 'ac') {
            return 'list-item-active';
        } 
        return 'list-item-inactive';
    }

	renderTranTypeGrid() {
		const { classes } = this.props;
		const agStyles = `ag-theme-balham ${classes.agThemeModified}`;
		return (
			<Grid container style={{"height": "60vh"/* , width: "96%", marginLeft: "2%", marginTop: "10px" */}} >
				<Grid item  md={12} sm={12} xs={12}>
					<div className={agStyles}>
						<AgGridReact
							onGridReady={this.handleOnTranTypeGridReady}
							columnDefs={[
								/* {
									headerName: '_id',
									field: '_id'
								}, */{
									headerName: 'code',
									field: 'code',
									sortable: true
								},{
									headerName: 'name',
									field: 'name',
									sortable: true
								},{
									headerName: 'tag',
									field: 'tag',
									sortable: true,
									cellRenderer: (params) => {
										// let _id = params.data._id;
										// let n = `<a href="${Constants.URLs.TRAN_TYPES + '/patch/' + _id + '/N'}">NA</a>`;
										// let c = `<a href="${Constants.URLs.TRAN_TYPES + '/patch/' + _id + '/C'}">Credit Only</a>`;
										// let d = `<a href="${Constants.URLs.TRAN_TYPES + '/patch/' + _id + '/D'}">Debit Only</a>`;
										// let b = `<a href="${Constants.URLs.TRAN_TYPES + '/patch/' + _id + '/B'}">Both</a>`;
										let n = ''; let c = ''; let d = ''; let b = '';
										switch (params.value) {
											case 'B':
												return n + c + d + '<strong>Both</strong>';
											case 'C':
												return n + '<strong>Credit Only</strong>' + d + b;
											case 'D':
												return n + c + '<strong>Debit Only</strong>' + b;
											default:
												return '<strong>NA</strong>' + c + d + b;
										}
									}
								}
							]}
							className="my-custom-header"
							rowData={this.state.tranTypes}
							rowSelection="multiple"
							getRowClass={this.handleRowHighlights}
							onSelectionChanged={this.onTranTypeSelectionChanged.bind(this)}
							animateRows
						/>
					</div>
				</Grid>
			</Grid>
		)
	}

	renderModalNewItem() {
		return (
			<NewListItem 
				isMajor={this.state.open.addMajorModal} 
				listCode={this.state.selected.code} 
				listName={this.state.selected.name}
				display={this.state.open.createModal}
				onCancel={() => this.handleCloseModal('add')}
				onSubmit={(params) => this.handleNewListItemSubmit(params)}
			/>
		)
	}

	render() {
		return (
			<div className="content-fluid">
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							{/* <div className="card-header">
								<h4 className="card-title">List Items</h4>
								<p className="card-category">View and manage List Items.</p>
							</div> */}
							<div className="card-body">
								{this.renderListSelection()}
								<div className="row">
									<div className={this.state.showTranTypeGrid ? "col-md-6" : "col-md-12"}>
										{this.renderActions()}
										{this.state.listItems.length > 0 && this.renderGrid()}
									</div>
									<div className="col-md-6">
										{this.state.showTranTypeGrid && this.renderTranTypeActions()}
										{this.state.showTranTypeGrid && this.renderTranTypeGrid()}
									</div>
								</div>
                                {this.state.open.createModal && this.renderModalNewItem()}
                                
                                <Dialog
                                open={this.state.open.disableConfirmation || this.state.open.enableConfirmation}><Typography>
                                    {`Are you sure to ${this.state.open.disableConfirmation ? 'disable' : 'enable'} the list item?`}
                                </Typography><Button
                                    onClick={()=>{
                                        if (this.state.open.disableConfirmation) {
                                            this.handleDeleteSelection("in")
                                        } else {
                                            this.handleDeleteSelection("ac")
                                        }
                                    }}
                                >Yes</Button><Button onClick={()=>this.handleCloseModal("")}>No</Button></Dialog>
                                

								{this.state.openOverlay && <LoadingOverlay />}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

ListItem.propTypes = {
	classes : PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withStyles(ListItemStyle)(ListItem)));
